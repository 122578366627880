@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s ease-in-out;
}
.image-container {
  overflow: hidden;
  /* display: inline-block; */
}

.image-container img {
  transition: transform 1s ease;
}

.image-container:hover img {
  transform: scale(1.1);
}
