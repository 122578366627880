@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

.title-styling {
  font-family: "Space Grotesk", sans-serif;
  font-size: 32px;
}
.text-style {
  font-family: "Roboto", sans-serif;
}

#home-heading {
  color: #183b56;
  font-family: "Space Grotesk", sans-serif;
}
#home-para {
  color: #183b56;
  font-family: "Roboto", sans-serif;
}

/* ************************************MISSION-VISION-VALUES****************************  */

#home-cards {
  background-color: #183b56;
  width: 100%;
}
.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
#vision-img {
  height: 83.93px;
  width: 83.93px;
  margin: 0px auto;
}
#Mission-img {
  height: 79.93px;
  width: 79.93px;
  margin: 0px auto;
}
#corevalues-img {
  height: 140.08px;
  width: 140.08px;
  margin: 0px auto;
}

/* ******************************* Our Achievements ******************************************** */
.main-title {
  margin-top: 80px;
}

/* ************************* our current programs ************************* */

.accordion-body {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  /* justify-content: space-evenly; */
}

/* **************************************** Affiliation ******************************** */

@media (min-width: 1200px) {
  .Gvt-Logo {
    padding-bottom: 2rem;
  }
  .vision-content {
    height: full;
  }
  .logo-width {
    width: 150px;
  }
}

@media (min-width: 992px) {
  .skills-inner-div {
    width: 50%;
  }
  .vision-content {
    height: 446px !important ;
  }
  .description {
    padding: 0px 40px;
    margin: 0px 40px;
  }
  #home-heading {
    font-size: 66px !important;
    line-height: 5rem !important;
  }
  .skills-img {
    padding-top: 40px;
    margin-top: 40px;
  }
  #home-para {
    font-size: 20px;
  }
  .ourAchHeadings {
    line-height: 60px;
  }
  .logo-width {
    width: 150px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .vision-content {
    height: 519px !important ;
  }
  #home-heading {
    font-size: 45px !important;
  }
  .skills-inner-div {
    width: 50%;
  }
  .description {
    padding: 0px 40px;
    margin: 50px 40px 0px 40px;
  }
  .affiliation-col {
    margin-bottom: 2rem; /* Adjust the value as needed */
  }
  #home-para {
    font-size: 20px;
  }
  .ourAchHeadings {
    line-height: 40px;
  }
  .ourAchImgs {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .Gvt-Logo {
    margin-bottom: 1.2rem;
  }
  .new-img {
    width: 20px;
  }
  .about-div {
    width: 100%;
  }
  .logo-width {
    width: 150px;
  }
}

@media (max-width: 768px) {
  .main-div-skills {
    padding: 0px;
    margin: 0px;
  }
  .skills-inner-div {
    width: 75%;
    padding-top: 40px;
  }
  #home-heading {
    font-size: 32px;
  }
  #home-para {
    font-size: 20px;
  }
  .ourAchHeadings {
    line-height: normal;
  }
  .accordion-col {
    padding-bottom: 30px;
  }
  .about-div {
    width: 100%;
  }
  .skills-inner-div {
    width: 100%;
    padding: 0;
  }

  .skills-div {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .description {
    padding: 0px 40px;
    margin: 50px 40px 0px 40px;
  }
  .logo-width {
    width: 150px;
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  .new-img {
    width: 10px;
  }
}
