body {
  overflow-x: hidden;
}
.text-style {
  font-family: "Roboto", sans-serif;
}
.about-heading {
  color: #183b56;
  font-size: 36px;
  font-family: "Space Grotesk", sans-serif;
  text-align: center;
  padding: 50px 120px 10px 120px;
}
.founder-name {
  font-weight: medium;
  font-size: 34px;
  /* margin-bottom: 20px; */
}
.founder-pic-styling {
  height: 550px;
  width: 500px;
}

/* .co-founder-pic-styling {
  height: 550px;
  width: 500px;
} */
.join-us-styling {
  /* line-height: 1.5rem; */
  font-family: "Roboto", sans-serif;
}

#btn1:hover {
  background-color: #50b5f8;
}

.award1 {
  height: 330px;
  width: 380px;
}
.award2 {
  height: 300px;
  width: 350px;
}

.textColor {
  color: #333;
}
#more {
  display: none;
}

.btn-space {
  cursor: pointer;
}

@media (max-width: 425px) {
  .founder-pic-styling {
    height: 430px;
    width: 400px;
    object-fit: contain;
  }
}
@media (max-width: 768px) {
  .about-heading {
    text-align: center;
    padding: 0px;
    color: #183b56;
    font-size: 30px;
    font-family: "Space Grotesk", sans-serif;
  }
  .img-distance {
    margin-bottom: 15px;
  }
  .icon-responsiveness {
    align-items: center;
  }
  .btn-space {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .founder-pic-styling {
    height: 460px;
    width: 330px;
    object-fit: contain;
  }
}
