.blurhash-img {
  width: 430px !important;
  height: 500px !important;
}
@media (max-width: 375px) {
  .blurhash-img {
    max-width: 280px !important;
    max-height: 330px !important;
  }
}
@media (min-width: 425px) and (max-width: 767px) {
  .blurhash-img {
    width: 350px !important;
    height: 360px !important;
  }
}
@media (min-width: 375px) and (max-width: 425px) {
  .blurhash-img {
    width: 330px !important;
    height: 360px !important;
  }
}
