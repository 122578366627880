
.shimmer-line {
  background-color: #f6f7f8;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 20px; /* Adjust the height of each line as needed */
}

.shimmer-line::before,
.shimmer-circle::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
