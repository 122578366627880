.acit-style {
  padding-bottom: 1rem;
}

@media (max-width: 1200px) {
  .acit-style {
    width: 24rem;
    margin: 0 auto;
  }
  /* .schools-imgs {
    height: 400px;
    width: 550px;
    object-fit: cover;
  } */
}

@media (max-width: 768px) {
  .img-responsiveness {
    width: 100%;
  }
  .img-distance {
    margin-bottom: 20px;
  }
  .acit-style {
    text-align: center;
  }
  .contact-width {
    width: 100%;
    padding-bottom: 15px;
  }
  
}

@media (min-width: 768px) and(max-width: 992px) {
  .img-responsiveness {
    height: "100px";
  }
  .contact-width {
    width: 75%;
  }
  /* .schools-imgs {
    height: 400px;
    width: 550px;
    object-fit: cover;
  } */
}
@media (max-width: 425px) {
  .acit-style {
    font-size: 16px;
    width: 12rem;
  }
}
@media (min-width: 992px) {
  .img-responsiveness {
    height: "400px";
    width: "550px";
  }
  .contact-width {
    width: 75%;
  }
  /* .schools-imgs {
    height: 400px;
    width: 550px;
    object-fit: cover;
  } */
}
