.contact-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: none;
  background-color: #ffffff;
  border-radius: 8px;
  min-height: 200px;
  max-width: 250px;
  margin: 0 auto;
}

.contact-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #6c757d;
  margin-bottom: 10px;
}

.card-title {
  font-size: 1.25rem;
  color: #6c757d;
  font-weight: bold;
  margin-top: 10px;
}

.text-muted {
  color: #6c757d;
  font-size: 1rem;
}
.hoverEffect {
  cursor: pointer;
}
@media (max-width: 768px) {
  .contact-card {
    max-width: 100%;
  }
}
