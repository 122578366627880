.btn1 {
  background-color: #183b56;
  color: white;
  border: none;
  transition: background-color 0.3s;
}
.btn1:hover {
  background-color: #50b5f8;
}
.textColor {
  font-family: "Space Grotesk", sans-serif;
  color: #183b56;
}
.textColor2 {
  font-family: "Roboto Mono", sans-serif !important;
  color: #183b56 !important;
}
.dropdownEle {
  font-size: 14px;
}
.dropdownEle:hover {
  background-color: #183b56 !important;
  color: white !important;
}
.btn2:hover {
  color: #50b5f8 !important;
}
.btn2Active {
  color: #50b5f8 !important;
}
.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  margin-top: 0;
}

.videosrc {
  width: 50% !important;
  height: 25% !important;
}
.backGroundColor {
  background-color: #183b56;
}
.socialLinks {
  color: #183b56;
}
.socialLinks:hover {
  background-color: #50b5f8 !important;
  color: white !important;
}

.hamburger-icon {
  display: none;
}

.navbar-collapse.hide {
  display: none !important;
}

.drawer .ant-drawer-content-wrapper {
  width: 250px !important;
  height: 50vh !important;
  top: 25vh !important;
}

.drawer .ant-drawer-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  background: #ffffff;
  pointer-events: auto;
  padding: 10px 20px;
}

.drawer .navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 20px;
}

.drawer .nav-item {
  padding: 10px 0;
}

.drawer .nav-link {
  text-decoration: none;
  padding: 10px 15px;
}

.drawer .dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 20px;
}

.drawer .dropdown-item {
  display: block;
  padding: 8px 15px;
  color: #000;
  text-decoration: none;
}

.drawer .dropdown-item:hover {
  background-color: #f8f9fa;
}

.btn2 {
  display: block;
  width: 100%;
}

.textColor2 {
  color: #000;
}

.fw-bolder {
  font-weight: bolder;
}

.fs-6 {
  font-size: 1rem;
}

.responsive {
  display: flex;
  gap: 50px;
}

/********************************** footer ************************************* */

.footer-container {
  display: flex;
  justify-content: even;
}

.footer-section {
  width: 25%;
}

.footer-section img {
  width: 14rem;
}

.footer-social-links {
  display: flex;
  gap: 3rem;
}

.footer-contact-links {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* privacy policy */
.fontStyle {
  font-family: "Roboto Mono", sans-serif !important;
}



/* ************************** media queries ************************* */

@media (max-width: 576px) {
  .footer-container {
    flex-direction: column;
  }
  .logo-styling {
    width: 10rem;
  }
  .footer-section {
    width: 100%;
    margin-bottom: 1rem;
  }

  .footer-contact-links {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .hamburger-icon {
    display: inline-block;
  }
  .responsive {
    flex-direction: column;
    gap: 7px;
  }
}
@media (max-width: 1200px) {
  .responsive {
    flex-direction: column;
    gap: 10px;
  }
}
@media (min-width: 576px) {
  .logo-styling {
    width: 16rem;
    padding-left: 10px;
    padding-right: 10px;
  }
}
