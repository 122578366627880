.contact-us-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .contact-us-container {
    flex-direction: row;
  }
  .img-contactus {
    display: flex;
    flex-direction: row;
    /* max-height: 45rem; */
  }

  .contact-us-form {
    flex: 1;
  }

  .contact-us-image {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .contact-us-image img {
    height: 100%;
    object-fit: cover;
  } */
}

/* @media (max-width: 991px) {
  .contact-us-image img {
    width: 100%;
    object-fit: cover;
  }
} */

.btn-submit {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .img-contactus {
    flex-direction: column-reverse;
    gap: 1rem;
  }
}
