#heading {
  font-family: "Oswald", sans-serif;
}
h2 {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  unicode-bidi: isolate;
}
.span-style:hover{
  text-decoration: underline;
  cursor: pointer;
}
.span2-style:hover{

  cursor: pointer;
}